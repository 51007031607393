import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { getLoginServiceUrl } from '../util/loginUrl.ts';
import { tenants } from '../util/tenant.ts';

let accessToken: string = undefined;
let expiresAt: string = undefined;
let idToken: string = undefined;

export const invalidateClientTokens = () => {
  expiresAt = undefined;
  accessToken = undefined;
  idToken = undefined;
};

const getIsAuthenticated = async () => {
  if (typeof window === 'undefined') return true;
  if (moment().add(2, 'minute') > moment(expiresAt)) await refreshTokens();

  return Boolean(accessToken);
};

const getEmail = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  if (!idToken) return;

  const decoded = jwtDecode<{ email?: string }>(idToken);
  return decoded?.email;
};

const getEmailDomain = async () => {
  const email = await getEmail();
  if (!email) return;

  return email.split('@')?.[1];
};

const getIdToken = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return idToken;
};

const getAccessToken = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return accessToken;
};

const getExpiresAt = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return expiresAt;
};

const canChangePassword = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return false;

  try {
    // Allowed to change password as long as there are no identities.
    const result = jwtDecode<{ identities?: Array<unknown> }>(idToken);
    if (!result) return false;

    return !result.identities || result.identities.length === 0;
  } catch (err) {
    log.error(`Failed to decode id token: ${err.message}`, err);
  }
  return false;
};

type ChangePasswordArgs = {
  currentPassword: string;
  newPassword: string;
  tenant: string;
  module: ModuleType;
};
const changePassword = async (args: ChangePasswordArgs) => {
  const isAllowed = await canChangePassword();
  if (!isAllowed)
    return {
      success: false,
      failReason: 'Not allowed',
    };

  try {
    await axios({
      url: `/api/auth/changePassword`,
      method: 'POST',
      data: {
        currentPassword: args.currentPassword,
        newPassword: args.newPassword,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        tenant: args.tenant,
        module: args.module,
      },
    });

    return { success: true };
  } catch (err) {
    const defaultFailReason = 'Something went wrong. Please try again later.';

    if (!err.response) return { success: false, failReason: defaultFailReason };

    return {
      success: false,
      failReason:
        err.response.status < 500
          ? (err.response.data?.message ?? defaultFailReason)
          : defaultFailReason,
    };
  }
};

const logout = async (rememberReturnPath = false) => {
  if (typeof window !== 'undefined' && rememberReturnPath) {
    storageApi.returnPath.set(window.location.href);
  }

  try {
    const logoutUrlResponse = await axios<{ logoutUrl: string }>({
      url: `/api/auth/logout?logout_uri=${window.location.origin}`,
      method: 'GET',
    });
    storageApi.clearAuthValues();
    window.location.assign(logoutUrlResponse.data.logoutUrl);
  } finally {
    storageApi.clearAuthValues();
  }
};

const getTenants = async (client: IGraphQLClient) => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return {};

  return tenants(client);
};

const getLoginRedirectUrl = () => {
  const baseUrl = getLoginServiceUrl();

  const redirectUrl = `${window.location.origin}/auth/callback`;
  return `${baseUrl}/App/Login?redirect_uri=${redirectUrl}`;
};

const handleAuthCallback = async (code: string, client_id: string) => {
  if (code && !client_id) {
    const idpRedirectUrl = `${getLoginServiceUrl()}/App/idpLogin?code=${code}`;
    window.location.assign(idpRedirectUrl);
    return;
  }

  try {
    const response = await axios<{
      access_token: string;
      expires_in: number;
      id_token: string;
    }>({
      url: `/api/auth/token?code=${code}&client_id=${client_id}`,
      method: 'GET',
    });

    if (response.status !== 200) throw new Error('Failed to login');

    const { access_token, expires_in, id_token } = response.data;
    accessToken = access_token;
    expiresAt = moment().add(expires_in, 'seconds').toISOString();
    idToken = id_token;

    return storageApi.returnPath.readAndClear('/');
  } catch (err) {
    log.error(`Authentication callback error: ${err.message}`, err);
    return '/Generic.html';
  }
};

const refreshTokens = async () => {
  try {
    const response = await axios<{
      access_token: string;
      expires_in: number;
      id_token: string;
    }>({
      url: `/api/auth/refresh`,
      method: 'GET',
    });

    if (response.status !== 200) throw new Error('Failed to refresh tokens');

    if (response.data) {
      const { access_token, expires_in, id_token } = response.data;
      accessToken = access_token;
      expiresAt = moment().add(expires_in, 'seconds').toISOString();
      idToken = id_token;
      return;
    }
  } catch (err) {
    if (err.response?.status !== 400)
      log.error(
        `Failed to refresh access token: ${err.message} - ${err.response?.status}`,
        err,
      );
  }
  storageApi.returnPath.set(window.location.href);
  window.location.assign('/auth/login');
};

export const authApi = {
  canChangePassword,
  changePassword,
  getAccessToken,
  getEmail,
  getEmailDomain,
  getExpiresAt,
  getIdToken,
  getIsAuthenticated,
  getLoginRedirectUrl,
  getTenants,
  handleAuthCallback,
  logout,
};
